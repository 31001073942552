import React, { useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  Table,
  PageHeader,
  Button,
  Modal,
  message,
  Form,
} from "antd";
import { observer } from "mobx-react-lite";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  getSchool,
  getUser,
  createUser,
  getUserById,
  updateUser,
  deleteUser,
} from "./action";
import Control from "./../../common/control";
import UserForm from "./../../common/user-form";
import UserSearch from "./../../common/user-search";
import { getUserType } from "./../../../config/app";
import { BASE_URL } from "./../../../config/app";
const { Content } = Layout;

const AdminUser = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataId, setDataId] = useState("");
  const [fileArr, setFileArr] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const formSearch = Form.useForm().form;

  const getDataSchool = async () => {
    let data = await getSchool();
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.school_id,
          school_name: item.school_name,
          school_address: item.school_address,
        });
      });
      setSchoolList(arr);
    }
  };

  const getData = async (search) => {
    let data = await getUser(search);
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.id,
          fullName: item.fullName,
          school_name: item.school_name,
          username: item.username,
          user_type: item.user_type,
        });
      });
      setDataList(arr);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setDataId("");
  };

  const edit = async (id) => {
    setDataId(id);
    showModal();
    let data = await getUserById(id);
    form.setFieldsValue({
      first_name: data.data.first_name,
      last_name: data.data.last_name,
      school_id: data.data.school_id,
      username: data.data.username,
      password: data.data.password_txt,
      email: data.data.email,
      user_type: data.data.user_type,
      position: data.data.position,
    });
  };

  const del = async (id) => {
    //alert("del:" + id);
    await deleteUser(id);
    message.success("ลบข้อมูลเรียบร้อย");
    getData();
  };

  const uploadFile = async (option) => {
    const { onSuccess, onError, file, action, onProgress } = option;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("uid", file.uid);
      formData.append("path", "profile");

      let url = BASE_URL + "/upload";
      let upload = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setFileArr([...fileArr, file.uid]);
      setFileList([...fileList, file]);
      onSuccess("Ok");
    } catch (err) {
      console.log("err:" + err);
      //return { status: false };
    }
  };

  const deleteFile = (f) => {
    let uid = f.uid;
    let files = fileArr;
    let index = files.indexOf(uid);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setFileArr([files]);
    let file_arr = [];
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].uid !== uid) {
        file_arr.push(fileList[i]);
      }
    }
    setFileList(file_arr);
  };

  const handleSubmit = async (data) => {
    let result;
    if (dataId == "") {
      result = await createUser(data, fileArr);
    } else {
      //alert(JSON.stringify(fileArr));
      result = await updateUser(dataId, data, fileArr);
    }
    if (result.status === true) {
      message.success("บันทึกข้อมูลเรียบร้อย");
    } else {
      message.error(result.error.data.message);
    }
    handleCancel();
    getData();
    setDataId("");
  };

  const handleSubmitSearch = async (data) => {
    getData(data);
  };

  const columns = [
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "fullName",
      key: "fullName",
      width: "30%",
    },
    {
      title: "สังกัด",
      dataIndex: "school_name",
      key: "school_name",
      width: "30%",
    },
    {
      title: "ผู้ใช้งาน",
      dataIndex: "username",
      key: "username",
      width: "15%",
    },
    {
      title: "ประเภท",
      dataIndex: "user_type",
      key: "user_type",
      width: "15%",
      render: (text, record) => {
        return getUserType(text);
      },
    },
    {
      title: "แก้ไข/ลบ",
      key: "action",
      render: (text, record) => (
        <Control rowId={record.id} edit={edit} del={del} />
      ),
    },
  ];

  useEffect(() => {
    getData();
    getDataSchool();
  }, []);

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>ข้อมูลผู้ใช้งาน</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        <PageHeader
          ghost={false}
          title="ข้อมูลผู้ใช้งาน"
          subTitle=""
          extra={[
            <Button
              shape="round"
              key="0"
              onClick={() => setShowSearch(!showSearch)}
              icon={<SearchOutlined />}
            >
              ค้นหา
            </Button>,
            <Button
              shape="round"
              key="1"
              type="primary"
              icon={<PlusOutlined />}
              onClick={showModal}
            >
              เพิ่มข้อมูล
            </Button>,
          ]}
        ></PageHeader>
        {showSearch === true && (
          <UserSearch
            form={formSearch}
            schools={schoolList}
            handleSubmit={handleSubmitSearch}
          />
        )}
        <Table bordered rowKey="id" dataSource={dataList} columns={columns} />
      </div>
      <Modal
        title={`${dataId === "" ? "เพิ่ม" : "แก้ไข"}ข้อมูลผู้ใช้งาน`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={"1"}
            type="primary"
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            บันทึก
          </Button>,
          <Button key={"2"} onClick={handleCancel}>
            ยกเลิก
          </Button>,
        ]}
      >
        <UserForm
          handleSubmit={handleSubmit}
          form={form}
          schools={schoolList}
          id={dataId}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          fileList={fileList}
        />
      </Modal>
    </Content>
  );
};

export default AdminUser;
