//export const BASE_URL = "http://localhost/supervision/api/api";

export const BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost/supervision/api/api"
    : "http://oss.nitednayok.com/api/api";

export const ASSET_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost/supervision/api"
    : "http://oss.nitednayok.com/api";

export const getUserType = (typeId) => {
  if (typeId === 1) {
    return "ผู้ดูแลระบบ";
  } else if (typeId === 2) {
    return "ผอ.เขต/รองผอ.เขต";
  } else if (typeId === 3) {
    return "ศึกษานิเทศก์";
  } else if (typeId === 4) {
    return "ผอ.รร./รักษาการฯ";
  } else {
    return "";
  }
};

export const userType = [
  {
    text: "ผู้ดูแลระบบ",
    value: 1,
  },
  {
    text: "ผอ.เขต/รองผอ.เขต",
    value: 2,
  },
  {
    text: "ศึกษานิเทศก์",
    value: 3,
  },
  {
    text: "ผอ.รร./รักษาการฯ",
    value: 4,
  },
];

export const getCheckUserAddSupervise = () => {
  let userType = localStorage.getItem("user_type");

  if (userType === "1" || userType === "3") {
    return true;
  } else {
    return false;
  }
};
