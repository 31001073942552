import axios from "axios";
import { BASE_URL } from "./../../../config/app";

export const getSchool = async (user, pass) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/school",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getAmphur = async (user, pass) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/amphur",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSchoolById = async (id) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/school/" + id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const createSchool = async (data, fileArr) => {
  const formData = new FormData();
  formData.append("school_name", data.school_name);
  formData.append(
    "school_address",
    data.school_address !== undefined ? data.school_address : ""
  );
  formData.append("amphur", data.amphur !== undefined ? data.amphur : "");
  if (fileArr !== undefined && fileArr.length > 0) {
    for (let i = 0; i < fileArr.length; i++) {
      //alert(fileArr[i]);
      formData.append("file[]", fileArr[i]);
    }
  }
  try {
    let data = await axios({
      method: "post",
      url: BASE_URL + "/school",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const updateSchool = async (id, data, fileArr) => {
  //alert(JSON.stringify(data));
  const formData = new FormData();
  formData.append("school_name", data.school_name);
  formData.append(
    "school_address",
    data.school_address !== undefined ? data.school_address : ""
  );
  formData.append("amphur", data.amphur !== undefined ? data.amphur : "");
  if (fileArr !== undefined && fileArr.length > 0) {
    for (let i = 0; i < fileArr.length; i++) {
      formData.append("file[]", fileArr[i]);
    }
  }
  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/school/" + id + "/update",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const deleteSchool = async (id) => {
  //alert(JSON.stringify(data));
  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/school/" + id + "/delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
