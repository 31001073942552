import axios from "axios";
import dayjs from "dayjs";
import { BASE_URL } from "./../../../config/app";

const setFormData = (data, fileArr) => {
  let formData = new FormData();
  //alert(JSON.stringify(data));
  formData.append("progress", data.progress);
  return formData;
};

export const createProgress = async (id, data, fileArr) => {
  let formData = setFormData(data, fileArr);

  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/supervise/" + id + "/progress",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSuperviseProgress = async (keyWord) => {
  try {
    let search = "?";
    if (keyWord !== undefined) {
      search +=
        "&search[first_name]=" +
        (keyWord.first_name !== undefined ? keyWord.first_name : "");

      search +=
        "&search[last_name]=" +
        (keyWord.last_name !== undefined ? keyWord.last_name : "");
      search +=
        "&search[school_id]=" +
        (keyWord.school_id !== undefined ? keyWord.school_id : "");
      search +=
        "&search[username]=" +
        (keyWord.username !== undefined ? keyWord.username : "");
      search +=
        "&search[email]=" + (keyWord.email !== undefined ? keyWord.email : "");
      search +=
        "&search[user_type]=" +
        (keyWord.user_type !== undefined ? keyWord.user_type : "");
    }

    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/data-progress" + search,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
