import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Avatar,
  Modal,
  Typography,
  Popconfirm,
  message,
} from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;
const chkMenuAminSchool = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 3) {
    chk = true;
  }
  return chk;
};

const chkMenuSupervision = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 3) {
    chk = true;
  }
  return chk;
};

const chkMenuSupervisionProgress = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 2) {
    chk = true;
  }
  return chk;
};

const chkMenuSupervisionReport = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 2 || userType === 3 || userType === 4) {
    chk = true;
  }
  return chk;
};

const chkMenuAdminUser = (userType) => {
  let chk = false;
  if (userType === 1) {
    chk = true;
  }
  return chk;
};

const chkMenuSupervisionPractice = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 4) {
    chk = true;
  }
  return chk;
};

const chkMenuSupervisionComment = (userType) => {
  let chk = false;
  if (userType === 1 || userType === 2) {
    chk = true;
  }
  return chk;
};

const MenuAdmin = ({ history, userType }) => {
  useEffect(() => {}, []);
  return (
    <div>
      <Menu theme={"dark"} defaultSelectedKeys={["supervisions"]} mode="inline">
        {chkMenuAminSchool(userType) === true && (
          <Menu.Item key="admin-school">
            <Link to="/admin-school">
              <RightCircleOutlined />
              <span>ข้อมูลโรงเรียน</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuSupervision(userType) === true && (
          <Menu.Item key="home2">
            <Link to="/supervision">
              <RightCircleOutlined />
              <span>บันทึกผลการนิเทศ</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuSupervisionProgress(userType) === true && (
          <Menu.Item key="supervision-progress" style={{ display: "none" }}>
            <Link to="/supervision-progress">
              <RightCircleOutlined />
              <span>ความก้าวหน้า</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuSupervisionComment(userType) === true && (
          <Menu.Item key="supervision-comment">
            <Link to="/supervision-comment">
              <RightCircleOutlined />
              <span>ความคิดเห็น</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuSupervisionPractice(userType) === true && (
          <Menu.Item key="supervision-practice">
            <Link to="/supervision-practice">
              <RightCircleOutlined />
              <span>การปฎิบัติของผู้รับการนิเทศ</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuSupervisionReport(userType) === true && (
          <Menu.Item key="supervision-report">
            <Link to="/supervision-report">
              <RightCircleOutlined />
              <span>รายงานผลการนิเทศ</span>
            </Link>
          </Menu.Item>
        )}
        {chkMenuAdminUser(userType) === true && (
          <Menu.Item key="admin-user">
            <Link to="/admin-user">
              <RightCircleOutlined />
              <span>ข้อมูลผู้ใช้งาน</span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logout">
          <Popconfirm
            title="ออกจากระบบ"
            onConfirm={() => {
              window.location = process.env.PUBLIC_URL + "/logout";
            }}
            onCancel={null}
            okText="ออกจากระบบ"
            cancelText="ยกเลิก"
            placement="right"
          >
            <Link to="/logout">
              <RightCircleOutlined />
              <span>ออกจากระบบ</span>
            </Link>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MenuAdmin;
