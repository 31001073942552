import { types, getParent, destroy } from "mobx-state-tree";

const school = types
  .model("school", {
    school_id: types.integer,
    school_name: types.string,
    school_address: types.string,
  })
  .actions((self) => ({
    remove() {
      getParent(self, 2).remove(self);
    },
  }));

const schoolStore = types
  .model({
    schools: types.optional(types.array(school), []),
  })
  .actions((self) => ({
    add(id, name, address) {
      self.schools.push({
        school_id: id,
        school_name: name,
        school_address: address,
      });
    },
    remove(item) {
      destroy(item);
    },
  }))
  .views((self) => ({
    get schoolList() {
      return self.schools;
    },
  }));

// สร้าง store
const store = schoolStore.create();

export default store;
