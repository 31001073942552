import axios from "axios";
import dayjs from "dayjs";
import { BASE_URL } from "./../../../config/app";

export const getSchool = async (amphur_id) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/school?amphur_id=" + amphur_id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getAmphur = async (user, pass) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/amphur",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSupForm = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/supervision-form",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSupIssues = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/supervision-issues",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSupMethod = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/supervision-method",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSupTool = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/supervision-tool",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
////////////////////////////////////////////////////////

export const getSupervise = async (keyWord) => {
  try {
    let search = "?";
    if (keyWord !== undefined) {
      search +=
        "&search[first_name]=" +
        (keyWord.first_name !== undefined ? keyWord.first_name : "");
      search +=
        "&search[last_name]=" +
        (keyWord.last_name !== undefined ? keyWord.last_name : "");
      search +=
        "&search[school_id]=" +
        (keyWord.school_id !== undefined ? keyWord.school_id : "");
      search +=
        "&search[username]=" +
        (keyWord.username !== undefined ? keyWord.username : "");
      search +=
        "&search[email]=" + (keyWord.email !== undefined ? keyWord.email : "");
      search +=
        "&search[user_type]=" +
        (keyWord.user_type !== undefined ? keyWord.user_type : "");
      search +=
        "&search[user_id]=" +
        (keyWord.user_id !== undefined ? keyWord.user_id : "");
      search +=
        "&search[amphur_id]=" +
        (keyWord.amphur_id !== undefined ? keyWord.amphur_id : "");
    }
    search += "&order_by=1";
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise" + search,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    console.log("search=", BASE_URL + "/supervise" + search);
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getSuperviseById = async (id) => {
  //alert(BASE_URL + "/supervise/data/" + id);
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/supervise/data/" + id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

const setFormData = (data, fileArr) => {
  let formData = new FormData();
  formData.append("school_id", data.school_id);
  formData.append("year", data.year);
  formData.append("term", data.term);
  formData.append("sup_date", dayjs(data.sup_date).format("YYYY-MM-DD"));
  formData.append("sup_results", data.sup_results);
  formData.append("sup_comment", data.sup_comment);
  //alert(JSON.stringify(data.supervisor));
  if (data.form !== undefined && data.form.length > 0) {
    for (let i = 0; i < data.form.length; i++) {
      formData.append("form[]", data.form[i]);
    }
  }

  if (data.issues !== undefined && data.issues.length > 0) {
    for (let i = 0; i < data.issues.length; i++) {
      formData.append("issues[]", data.issues[i]);
    }
  }

  if (data.method !== undefined && data.method.length > 0) {
    for (let i = 0; i < data.method.length; i++) {
      formData.append("method[]", data.method[i]);
    }
  }

  if (data.tool !== undefined && data.tool.length > 0) {
    for (let i = 0; i < data.tool.length; i++) {
      formData.append("tool[]", data.tool[i]);
    }
  }

  if (fileArr !== undefined && fileArr.length > 0) {
    for (let i = 0; i < fileArr.length; i++) {
      formData.append("file[]", fileArr[i]);
    }
  }

  if (data.supervisor !== undefined && data.supervisor.length > 0) {
    for (let i = 0; i < data.supervisor.length; i++) {
      formData.append("supervisor[]", data.supervisor[i]);
    }
  }

  if (data.sup_form_other !== undefined) {
    formData.append("sup_form_other", data.sup_form_other);
  }

  if (data.sup_issues_other !== undefined) {
    formData.append("sup_issues_other", data.sup_issues_other);
  }

  if (data.sup_method_other !== undefined) {
    formData.append("sup_method_other", data.sup_method_other);
  }

  if (data.sup_tool_other !== undefined) {
    formData.append("sup_tool_other", data.sup_tool_other);
  }

  return formData;
};

export const createSupervise = async (data, fileArr) => {
  let formData = setFormData(data, fileArr);

  try {
    let result = await axios({
      method: "post",
      url: BASE_URL + "/supervise",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    return {
      status: true,
      data: result.data
    };
  } catch (err) {
    return { status: false, error: err.response };
  }
};

export const updateSupervise = async (id, data, fileArr) => {
  let formData = setFormData(data, fileArr);

  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/supervise/" + id + "/update",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      }
    });
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const deleteUser = async (id) => {
  //alert(JSON.stringify(data));
  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/user/" + id + "/delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      }
    });
    return {
      status: true,
      data: data.data
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
