import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Card, Row, Col, Modal } from "antd";
import { auth } from "./action";

const Login = ({ history }) => {
  const loginError = () => {
    Modal.error({
      title: "ERROR",
      content: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
    });
  };
  const handleSubmit = async (value) => {
    let data = await auth(value.username, value.password);

    if (data.status === true) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user_type", data.user_type);
      localStorage.setItem("school_id", data.school_id);
      history.push(`${process.env.PUBLIC_URL}/supervision`);
    } else {
      //message.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
      loginError();
    }
  };

  return (
    <Row
      type="flex"
      className={"card-login-content"}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/bg.jpeg)`,
        backgroundSize: "cover",
      }}
    >
      <Col
        span={9}
        offset={7}
        xs={{ order: 1 }}
        sm={{ order: 2 }}
        md={{ order: 3 }}
        lg={{ order: 4 }}
      >
        <Card className={"card-login"}>
          <div style={{ textAlign: "center", marginTop: 5, marginBottom: 30 }}>
            <img
              src={process.env.PUBLIC_URL + "/logo2.png"}
              width="100%"
              style={{ maxWidth: 150 }}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: 30,
              fontSize: 18,
            }}
          >
            ระบบรายงานผลการนิเทศแบบออนไลน์
            <br />
            สำนักงานเขตพื้นที่การศึกษาประถมศึกษานครนายก
            <br />
            Online supervision educational report system(OSS)
          </div>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Form.Item
              label="ชื่อผู้ใช้งาน"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
