import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Avatar, Modal, Typography } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;
const MenuType2 = ({ history }) => {
  useEffect(() => {}, []);
  return (
    <Menu theme={"light"} defaultSelectedKeys={["supervisions"]} mode="inline">
      <Menu.Item key="home2">
        <Link to="/supervision">
          <RightCircleOutlined />
          <span>บันทึกผลการนิเทศ</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="supervision-report">
        <Link to="/supervision-report">
          <RightCircleOutlined />
          <span>รายงานผลการนิเทศ</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Link to="/logout">
          <RightCircleOutlined />
          <span>ออกจากระบบ</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default MenuType2;
