import React, { useState, useEffect } from "react";
import { Steps, Divider, Button, Modal, Form, Input } from "antd";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "./../../config/app";

const { Step } = Steps;
const { TextArea } = Input;

const Des = ({ item, getData }) => {
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {};
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const saveComment = async () => {
    const formData = new FormData();
    formData.append("comment", comment);
    let result = await axios({
      method: "post",
      url: BASE_URL + "/supervise/" + commentId + "/update-comment",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    setIsModalVisible(false);
    getData();
  };

  useEffect(() => {
    setCommentId(item.sup_comment_id);
  }, []);

  return (
    <div>
      {item.sup_comment}
      <br />
      วันที่ : {dayjs(item.created_at).format("DD/MM/YYYY")}
      <br />
      {localStorage.getItem("user_type") == "4" && (
        <>
          <Button
            type="link"
            onClick={showModal}
            icon={<EditOutlined />}
          ></Button>
          แก้ไข
        </>
      )}
      <Modal
        title={`แก้ไขความคิดเห็น`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={"1"}
            type="primary"
            form="myForm"
            key="submit"
            onClick={saveComment}
          >
            บันทึก
          </Button>,
          <Button key={"2"} onClick={handleCancel}>
            ยกเลิก
          </Button>
        ]}
      >
        <Form layout="vertical" id="myFormComment">
          <Form.Item label="ความคิดเห็น" name="comment">
            <TextArea
              rows={4}
              defaultValue={item.sup_comment}
              onChange={(v) => setComment(v.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const Comment = ({ data, getData }) => {
  return (
    <>
      <Steps progressDot current={100} direction="vertical">
        {data.map((item, index) => (
          <Step
            key={`step${index}`}
            title={`${item.user.first_name} ${item.user.last_name}`}
            description={<Des item={item} getData={getData} />}
          />
        ))}
      </Steps>
    </>
  );
};

export default Comment;
