import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "./../../config/app";

const { Option } = Select;

const SchoolForm = ({
  handleSubmit,
  data,
  form,
  amphur,
  uploadFile,
  deleteFile,
  fileList,
}) => {
  return (
    <Form form={form} layout="vertical" id="myForm" onFinish={handleSubmit}>
      <Form.Item
        label="ชื่อโรงเรียน"
        name="school_name"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="อำเภอ"
        name="amphur"
        rules={[{ required: true, message: "กรุณาเลือกข้อมูล" }]}
      >
        <Select showSearch placeholder="อำเภอ">
          {amphur.map((item, index) => (
            <Option key={`amphur_${index}`} value={item.amphur_id}>
              {item.amphur_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="ที่อยู่" name="school_address">
        <Input.TextArea />
      </Form.Item>
      <Upload
        customRequest={(option) => uploadFile(option)}
        onRemove={deleteFile}
        fileList={fileList}
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>แนบรูปภาพ</Button>
      </Upload>
    </Form>
  );
};

export default SchoolForm;
