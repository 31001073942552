import React, { useState, useEffect } from "react";
import { Steps, Divider } from "antd";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Step } = Steps;

const Des = ({ item }) => {
  return (
    <div>
      {item.sup_practice}
      <br />
      วันที่ : {dayjs(item.created_at).format("DD/MM/YYYY")}
    </div>
  );
};

const Practice = ({ data }) => {
  return (
    <>
      <Steps progressDot current={100} direction="vertical">
        {data.map((item, index) => (
          <Step
            key={`step${index}`}
            title={`${item.user.first_name} ${item.user.last_name}`}
            description={<Des item={item} />}
          />
        ))}
      </Steps>
    </>
  );
};

export default Practice;
