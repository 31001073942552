import React, { useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  Table,
  PageHeader,
  Button,
  Modal,
  message,
  Form,
} from "antd";
import { observer } from "mobx-react-lite";
import axios from "axios";
import {
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { getSchool, getSupervise, getAmphur } from "./action";
import { getUser } from "./../admin-user/action";
import Progress from "./../../common/progress";
import Practice from "./../../common/practice";
import SuperviseSearch from "./../../common/supervise-search";
import Comment from "./../../common/comment";
import { getUserType, BASE_URL } from "./../../../config/app";
const { Content } = Layout;

const Supervision = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [schoolList, setSchoolList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataId, setDataId] = useState("");

  const [userList, setUserList] = useState([]);

  const [form] = Form.useForm();
  const formSearch = Form.useForm().form;

  const [amphur, setAmphur] = useState([]);

  const getDataSchool = async () => {
    let data = await getSchool("");
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.school_id,
          school_name: item.school_name,
          school_address: item.school_address,
        });
      });
      setSchoolList(arr);
    }
  };

  const getData = async (search) => {
    //alert(JSON.stringify(search));
    let data = await getSupervise(search);
    let arr = [];
    console.log("data:", data.data);
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.sup_id,
          fullName: item.user.first_name + " " + item.user.last_name,
          school_name: item.school.school_name,
          date: item.sup_date,
          user: item.user,
          progress: item.progress,
          sup_practice: item.sup_practice,
          comment: item.comment,
          practice: item.practice,
          sup_date: item.sup_date,
        });
      });
      setDataList(arr);
    }
  };

  const getDataUser = async () => {
    let data = await getUser({});
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.id,
          fullName: item.fullName,
          school_name: item.school_name,
          username: item.username,
          user_type: item.user_type,
        });
      });
      setUserList(arr);
    }
  };

  const handleSubmitSearch = async (data) => {
    //alert(JSON.stringify(data));
    getData(data);
  };

  const getAmphurData = async () => {
    let data = await getAmphur();
    setAmphur(data.data);
  };

  const columns = [
    {
      title: "วันที่",
      dataIndex: "date",
      key: "date",
      width: "10%",
      className: "td_top",
      render: (text, record) => {
        return dayjs(record.sup_date).format("DD/MM/YYYY");
      },
    },
    {
      title: "โรงเรียน",
      dataIndex: "school_name",
      key: "school_name",
      width: "30%",
      className: "td_top",
      render: (text, record) => (
        <div>
          {record.school_name}
          <br />
          <UserOutlined /> &nbsp;
          {record.user.first_name} {record.user.last_name}
        </div>
      ),
    },
    {
      title: "ความก้าวหน้า",
      dataIndex: "sup_id",
      key: "sup_id",
      width: "20%",
      className: "td_top",
      render: (text, record) => {
        if (record.progress !== undefined) {
          return <Progress data={record.progress} />;
        }
      },
    },
    {
      title: "การปฎิบัติงานของผู้รับนิเทศ",
      dataIndex: "user.id",
      key: "user.id",
      width: "20%",
      className: "td_top",
      //render: (text, record) => record.sup_practice,
      render: (text, record) => {
        if (record.practice !== undefined) {
          return <Practice data={record.practice} />;
        }
      },
    },
    {
      title: "ความคิดเห็นของผู้บริหารการศึกษา",
      dataIndex: "up_id",
      key: "up_id",
      width: "20%",
      className: "td_top",
      //render: (text, record) => record.sup_practice,
      render: (text, record) => {
        if (record.comment !== undefined) {
          return <Comment data={record.comment} />;
        }
      },
    },
    {
      title: "ส่งออก",
      key: "action",
      render: (text, record) => (
        <Button type="primary" ghost onClick={() => download(record.id)}>
          Export
        </Button>
      ),
    },
  ];

  const download = (id) => {
    //window.open(BASE_URL + "/supervise/export");
    window.open(BASE_URL + "/supervise/export-pdf?id=" + id);
  };

  const getSchoolAmphur = async (v) => {
    if (v === undefined) {
      v = "";
    }
    let data = await getSchool(v);
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.school_id,
          school_name: item.school_name,
          school_address: item.school_address,
        });
      });
      setSchoolList(arr);
    }
  };

  useEffect(() => {
    const startFetchData = async () => {
      //await getData();
      await getDataSchool();
      await getAmphurData();
      await getDataUser();
    };
    startFetchData();
  }, []);

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>รายงานการนิเทศ</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        <PageHeader
          ghost={false}
          title="รายงานการนิเทศ"
          subTitle=""
          extra={[]}
        ></PageHeader>
        {showSearch === true && (
          <SuperviseSearch
            form={formSearch}
            schools={schoolList}
            handleSubmit={handleSubmitSearch}
            amphur={amphur}
            userList={userList}
            getSchoolAmphur={getSchoolAmphur}
          />
        )}

        {dataList.length > 0 && (
          <div style={{ textAlign: "right", paddingBottom: 5 }}>
            {/*<Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => download()}
            >
              Download
            </Button>*/}
          </div>
        )}
        <Table rowKey="id" dataSource={dataList} columns={columns} />
      </div>
    </Content>
  );
};

export default Supervision;
