import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Avatar,
  Modal,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import MenuAdmin from "./menu-admin";
import moment from "moment";
import MenuType2 from "./menu-type2";
import { BASE_URL, ASSET_URL } from "./../../config/app";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Paragraph } = Typography;

const me = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/me",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return {
      status: true,
      username: data.data.username,
      user_type: data.data.user_type,
      image: data.data.image,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

class LayoutMain extends React.Component {
  state = {
    collapsed: false,
    name: "",
    img: "https://www.w3schools.com/howto/img_avatar.png",
    userType: 0,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  async componentDidMount() {
    let profile = await me();

    let image = this.state.img;
    if (
      profile.image !== undefined &&
      profile.image !== null &&
      profile.image !== ""
    ) {
      image =
        ASSET_URL +
        "/public/uploads/profile/" +
        profile.image +
        "?t=" +
        moment().toISOString();
    }
    this.setState({
      name: profile.username,
      userType: profile.user_type,
      img: image,
    });
    console.log("profile", profile);
  }

  async uploadFile(option) {
    const { onSuccess, onError, file, action, onProgress } = option;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("uid", file.uid);
      //formData.append("path", "supervise");

      let url = BASE_URL + "/upload-profile";
      let upload = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      onSuccess("Ok");
      //alert(JSON.stringify(upload));
      this.setState({
        img:
          ASSET_URL +
          "/public/uploads/profile/" +
          upload.data.file +
          "?t=" +
          moment().toISOString(),
      });
    } catch (err) {
      console.log("err:" + err);
      //return { status: false };
    }
  }

  render() {
    return (
      <div>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/top.jpeg"}
            width="100%"
            style={{ maxHeight: 150 }}
          />
        </div>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            theme={"dark"}
          >
            <div style={{ padding: 10, fontSize: 22 }}>
              <img src={process.env.PUBLIC_URL + "/logo2.png"} width="35" />
              <span style={{ marginLeft: 10, color: "#FFF" }}>
                <b style={{ color: "#FFC300" }}>OSS</b> SYSTEM
              </span>
            </div>
            <MenuAdmin userType={this.state.userType} />
          </Sider>
          <Layout>
            <Header
              style={{
                background: "#fff",
                padding: 0,
                paddingLeft: 10,
                whiteSpace: "nowrap",
              }}
            >
              <Paragraph ellipsis={{ rows: 1, expandable: false }}>
                <Upload
                  customRequest={(option) => this.uploadFile(option)}
                  showUploadList={false}
                >
                  <Avatar src={this.state.img} size="large" />
                  &nbsp; สวัสดีคุณ : {this.state.name}
                </Upload>
              </Paragraph>
            </Header>
            {this.props.children}
            <Footer style={{ textAlign: "center" }}>
              ระบบรายงานผลการนิเทศแบบออนไลน์
              <br />
              Online supervision educational report system : OSS
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default LayoutMain;
