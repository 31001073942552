import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Avatar, Modal, Typography } from "antd";

const { Header, Content, Footer, Sider } = Layout;

const Logout = ({ history }) => {
  localStorage.removeItem("token");
  useEffect(() => {}, []);
  return <Redirect to="/login" />;
};

export default Logout;
