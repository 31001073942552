import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Avatar, Modal, Typography } from "antd";

const { Header, Content, Footer, Sider } = Layout;

const Home = ({ history }) => {
  useEffect(() => {}, []);
  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>User</Breadcrumb.Item>
        <Breadcrumb.Item>Bill</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        Home
      </div>
    </Content>
  );
};

export default Home;
