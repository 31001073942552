import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { userType } from "./../../config/app";

const { Option } = Select;

const UserForm = ({
  handleSubmit,
  schools,
  id,
  form,
  uploadFile,
  deleteFile,
  fileList,
}) => {
  return (
    <Form form={form} layout="vertical" id="myForm" onFinish={handleSubmit}>
      <Form.Item
        label="ชื่อ"
        name="first_name"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="นามสกุล"
        name="last_name"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item label="ตำแหน่ง" name="position" rules={[]}>
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="สังกัด"
        name="school_id"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select showSearch placeholder="เลือกสังกัด">
          {schools.map((item, index) => (
            <Option key={`data_school_${index}`} value={item.id}>
              {item.school_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="ชื่อผู้ใช้งาน"
        name="username"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="รหัสผ่าน"
        name="password"
        rules={[
          { required: id === "" ? true : false, message: "กรุณาใส่ข้อมูล" },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="อีเมล์"
        name="email"
        rules={[
          {
            type: "email",
            message: "รูปแบบอีเมล์ไม่ถูกต้อง",
          },
          { required: true, message: "กรุณาใส่ข้อมูล" },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label="ประเภทผู้ใช้งาน"
        name="user_type"
        rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select placeholder="เลือกประเภทผู้ใช้งาน">
          {userType.map((item, index) => (
            <Option key={`data_user_type_${index}`} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Upload
        customRequest={(option) => uploadFile(option)}
        onRemove={deleteFile}
        fileList={fileList}
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>แนบรูปภาพ</Button>
      </Upload>
    </Form>
  );
};

export default UserForm;
