import axios from "axios";
import { BASE_URL } from "./../../../config/app";

export const getSchool = async () => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/school",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getUser = async (keyWord) => {
  try {
    let search = "?";
    if (keyWord !== undefined) {
      search +=
        "&search[first_name]=" +
        (keyWord.first_name !== undefined ? keyWord.first_name : "");

      search +=
        "&search[last_name]=" +
        (keyWord.last_name !== undefined ? keyWord.last_name : "");
      search +=
        "&search[school_id]=" +
        (keyWord.school_id !== undefined ? keyWord.school_id : "");
      search +=
        "&search[username]=" +
        (keyWord.username !== undefined ? keyWord.username : "");
      search +=
        "&search[email]=" + (keyWord.email !== undefined ? keyWord.email : "");
      search +=
        "&search[user_type]=" +
        (keyWord.user_type !== undefined ? keyWord.user_type : "");
    }

    let data = await axios({
      method: "get",
      url: BASE_URL + "/user" + search,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const getUserById = async (id) => {
  try {
    let data = await axios({
      method: "get",
      url: BASE_URL + "/user/" + id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(JSON.stringify(loginData.data));
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const createUser = async (data, fileArr) => {
  const formData = new FormData();

  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("school_id", data.school_id);
  formData.append("username", data.username);
  formData.append("password", data.password);
  formData.append("email", data.email);
  formData.append("user_type", data.user_type);
  formData.append("position", data.position);
  if (fileArr !== undefined && fileArr.length > 0) {
    for (let i = 0; i < fileArr.length; i++) {
      //alert(fileArr[i]);
      formData.append("file[]", fileArr[i]);
    }
  }
  try {
    let result = await axios({
      method: "post",
      url: BASE_URL + "/user",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return {
      status: true,
      data: result.data,
    };
  } catch (err) {
    return { status: false, error: err.response };
  }
};

export const updateUser = async (id, data, fileArr) => {
  //alert(JSON.stringify(data));
  const formData = new FormData();
  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("school_id", data.school_id);
  formData.append("username", data.username);
  formData.append("password", data.password === undefined ? "" : data.password);
  formData.append("email", data.email);
  formData.append("user_type", data.user_type);
  formData.append("position", data.position);
  if (fileArr !== undefined && fileArr.length > 0) {
    for (let i = 0; i < fileArr.length; i++) {
      formData.append("file[]", fileArr[i]);
    }
  }
  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/user/" + id + "/update",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};

export const deleteUser = async (id) => {
  //alert(JSON.stringify(data));
  try {
    let data = await axios({
      method: "POST",
      url: BASE_URL + "/user/" + id + "/delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return {
      status: true,
      data: data.data,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
