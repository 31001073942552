import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Card } from "antd";
import { userType } from "./../../config/app";

const { Option } = Select;

const SuperviseSearch = ({
  handleSubmit,
  schools,
  form,
  amphur,
  userList,
  getSchoolAmphur,
}) => {
  const _span = 8;
  return (
    <Card style={{ marginBottom: 16 }} type="inner" title="ค้นหา">
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        id="myFormSearch"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 16]}>
          {localStorage.getItem("user_type") !== "4" && (
            <Col span={_span}>
              <Form.Item label="อำเภอ" name="amphur_id">
                <Select
                  showSearch
                  placeholder="เลือกอำเภอ"
                  onChange={(v) => getSchoolAmphur(v)}
                  allowClear
                  onClear={() => getSchoolAmphur("")}
                >
                  {amphur.map((item, index) => (
                    <Option
                      key={`search_amphur_${index}`}
                      value={item.amphur_id}
                    >
                      {item.amphur_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("user_type") !== "4" && (
            <Col span={_span}>
              <Form.Item label="โรงเรียน" name="school_id">
                <Select showSearch placeholder="เลือกสังกัด" allowClear>
                  {schools.map((item, index) => (
                    <Option key={`search_school_${index}`} value={item.id}>
                      {item.school_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={_span}>
            {localStorage.getItem("user_type") !== "3" && (
              <Form.Item label="ศน" name="user_id">
                <Select
                  showSearch
                  placeholder="เลือก ศน"
                  allowClear
                  disabled={
                    localStorage.getItem("user_type") === "3" ? true : false
                  }
                >
                  {userList.map((item, index) => (
                    <Option key={`search_user_${index}`} value={item.id}>
                      {item.fullName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={_span}>
            <Form.Item wrapperCol={{ span: 16, offset: 8 }} name="btn">
              <Button htmlType={"submit"} type="primary">
                ค้นหา
              </Button>
              &nbsp;
              <Button htmlType={"reset"}>Reset</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SuperviseSearch;
