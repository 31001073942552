import React, { useState, useEffect } from "react";
import { Popconfirm, message, Divider, Tooltip, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const Control = ({
  rowId,
  edit,
  del,
  progress,
  comment,
  practice,
  showView,
  onView,
}) => {
  return (
    <div style={{ minWidth: 130 }}>
      <span>
        {showView === true && (
          <Tooltip title="ดู">
            <Button
              type="link"
              icon={<SearchOutlined />}
              onClick={() => onView(rowId)}
            ></Button>
          </Tooltip>
        )}
        <Divider type="vertical" />
        {edit !== undefined && (
          <Tooltip title="แก้ไข">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(rowId)}
            ></Button>
          </Tooltip>
        )}
        <Divider type="vertical" />
        {del !== undefined && (
          <Tooltip title="ลบ">
            <Popconfirm
              placement="topLeft"
              title={"ลบข้อมูล"}
              onConfirm={() => del(rowId)}
              okText="ลบ"
              cancelText="ยกเลิก"
            >
              <Button type="link" icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Tooltip>
        )}
        {progress !== undefined && (
          <Tooltip title="ความก้าวหน้า">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => progress(rowId)}
            ></Button>
          </Tooltip>
        )}
        {comment !== undefined && (
          <Tooltip title="บันทึกความคิดเห็น">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => comment(rowId)}
            ></Button>
          </Tooltip>
        )}
        {practice !== undefined && (
          <Tooltip title="บันทึกการปฎิบัติของผู้รับการนิเทศ">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => practice(rowId)}
            ></Button>
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export default Control;
