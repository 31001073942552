import axios from "axios";
import { BASE_URL } from "./../../../config/app";

export const auth = async (user, pass) => {
  const formData = new FormData();
  formData.append("username", user);
  formData.append("password", pass);
  try {
    let data = await axios({
      method: "post",
      url: BASE_URL + "/login",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      status: true,
      token: data.data.token,
      user_type: data.data.user_type,
      school_id: data.data.school_id,
    };
  } catch (err) {
    console.log("err:" + err);
    return { status: false };
  }
};
