import React, { useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  Table,
  PageHeader,
  Button,
  Modal,
  message,
  Form,
} from "antd";
import { observer } from "mobx-react-lite";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import store from "./state";
import {
  getSchool,
  createSchool,
  getSchoolById,
  updateSchool,
  deleteSchool,
  getAmphur,
} from "./action";
import Control from "./../../common/control";
import SchoolForm from "./../../common/school-form";
import { BASE_URL } from "./../../../config/app";
const { Content } = Layout;

const schoolContext = React.createContext(store);

const AdminSchool = observer(({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataId, setDataId] = useState("");
  const [amphur, setAmphur] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const getData = async () => {
    let data = await getSchool();
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.school_id,
          school_name: item.school_name,
          school_address: item.school_address,
        });
      });
      setDataList(arr);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setDataId("");
    setFileArr([]);
    setFileList([]);
  };

  const edit = async (id) => {
    setDataId(id);
    showModal();
    let data = await getSchoolById(id);
    form.setFieldsValue({
      school_name: data.data.school_name,
      school_address: data.data.school_address,
      amphur: data.data.amphur_id,
    });
    let file_arr = [];
    let file_objArr = [];
    //alert(JSON.stringify(data.data));
    if (data.data.files !== undefined && data.data.files.length > 0) {
      for (let i = 0; i < data.data.files.length; i++) {
        file_arr.push(data.data.files[i].uid);
        file_objArr.push({
          uid: data.data.files[i].uid,
          name: data.data.files[i].file_old_name, // file name
          status: "done",
          response: { status: "success" },
          url: data.data.files[i].url,
        });
      }
    }
    setFileArr(file_arr);
    setFileList(file_objArr);
  };

  const del = async (id) => {
    //alert("del:" + id);
    await deleteSchool(id);
    message.success("ลบข้อมูลเรียบร้อย");
    getData();
  };

  const handleSubmit = async (data) => {
    //alert(JSON.stringify(fileArr));
    if (dataId == "") {
      await createSchool(data, fileArr);
    } else {
      await updateSchool(dataId, data, fileArr);
    }
    message.success("บันทึกข้อมูลเรียบร้อย");
    handleCancel();
    getData();
    setDataId("");
  };

  const getAmphurData = async () => {
    let data = await getAmphur();
    setAmphur(data.data);
  };

  const uploadFile = async (option) => {
    const { onSuccess, onError, file, action, onProgress } = option;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("uid", file.uid);
      formData.append("path", "school");

      let url = BASE_URL + "/upload";
      let upload = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setFileArr([...fileArr, file.uid]);
      setFileList([...fileList, file]);
      onSuccess("Ok");
    } catch (err) {
      console.log("err:" + err);
      //return { status: false };
    }
  };

  const deleteFile = (f) => {
    let uid = f.uid;
    let files = fileArr;
    let index = files.indexOf(uid);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setFileArr([files]);
    let file_arr = [];
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].uid !== uid) {
        file_arr.push(fileList[i]);
      }
    }
    setFileList(file_arr);
  };

  const columns = [
    {
      title: "ชื่อโรงเรียน",
      dataIndex: "school_name",
      key: "school_name",
      width: "40%",
    },
    {
      title: "ที่อยู่",
      dataIndex: "school_address",
      key: "school_address",
      width: "40%",
    },
    {
      title: "แก้ไข/ลบ",
      key: "action",
      render: (text, record) => (
        <Control rowId={record.id} edit={edit} del={del} />
      ),
    },
  ];

  useEffect(() => {
    getData();
    getAmphurData();
  }, []);

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>ข้อมูลโรงเรียน</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        <PageHeader
          ghost={false}
          title="ข้อมูลโรงเรียน"
          subTitle=""
          extra={[
            <Button
              icon={<PlusOutlined />}
              shape="round"
              key="1"
              type="primary"
              onClick={showModal}
            >
              เพิ่มข้อมูล
            </Button>,
          ]}
        ></PageHeader>
        <Table rowKey="school_name" dataSource={dataList} columns={columns} />
      </div>
      <Modal
        title={`${dataId === "" ? "เพิ่ม" : "แก้ไข"}ข้อมูลโรงเรียน`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={"1"}
            type="primary"
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            บันทึก
          </Button>,
          <Button key={"2"} onClick={handleCancel}>
            ยกเลิก
          </Button>,
        ]}
      >
        <SchoolForm
          handleSubmit={handleSubmit}
          form={form}
          amphur={amphur}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          fileList={fileList}
        />
      </Modal>
    </Content>
  );
});

export default AdminSchool;
