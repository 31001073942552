import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Login from "./components/pages/login";
import Home from "./components/pages/home";
import LayoutMain from "./components/layout/layout-main";
import AdminSchool from "./components/pages/admin-school";
import AdminUser from "./components/pages/admin-user";
import Supervision from "./components/pages/supervise";
import SupervisionProgress from "./components/pages/supervise-progress";
import SupervisionReport from "./components/pages/supervise-report";
import SupervisionComment from "./components/pages/supervise-comment";
import SupervisionPractice from "./components/pages/supervise-practice";
import Logout from "./components/pages/logout";
import "antd/dist/antd.css";
import "./App.css";

const isAuth = () => {
  return localStorage.getItem("token") ? true : false;
};

const AutnRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() === true ? (
        <LayoutMain>
          <Component {...props} />
        </LayoutMain>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/main" component={Home} />
        <AutnRoute path="/home" component={Home} />
        <AutnRoute path="/admin-school" component={AdminSchool} />
        <AutnRoute path="/admin-user" component={AdminUser} />
        <AutnRoute path="/supervision" component={Supervision} />
        <AutnRoute
          path="/supervision-progress"
          component={SupervisionProgress}
        />
        <AutnRoute path="/supervision-report" component={SupervisionReport} />
        <AutnRoute path="/supervision-comment" component={SupervisionComment} />
        <AutnRoute
          path="/supervision-practice"
          component={SupervisionPractice}
        />
        <AutnRoute path="/logout" component={Logout} />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
