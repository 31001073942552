import React, { useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  Table,
  PageHeader,
  Button,
  Modal,
  message,
  Form
} from "antd";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import {
  getSchool,
  getSupervise,
  createSupervise,
  getSuperviseById,
  updateSupervise,
  deleteSupervise,
  getSupForm,
  getSupIssues,
  getSupMethod,
  getSupTool
} from "./../supervise/action";
import { createComment, getSuperviseComment } from "./action";
import Control from "./../../common/control";
import SuperviseForm from "./../../common/supervise-form";
import UserSearch from "./../../common/user-search";
import Progress from "./../../common/progress";
import Comment from "./../../common/comment";
import { getUserType, BASE_URL } from "./../../../config/app";
const { Content } = Layout;

const SupervisionComment = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataId, setDataId] = useState("");

  const [dataSupForm, setDataSupForm] = useState([]);
  const [dataSupIssues, setDataSupIssues] = useState([]);
  const [dataSupMethod, setDataSupMethod] = useState([]);
  const [dataSupTool, setDataSupTool] = useState([]);
  const [dataSix, setDataSix] = useState([]);

  const [fileArr, setFileArr] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [dataArray, setDataArray] = useState("");

  const [form] = Form.useForm();
  const formSearch = Form.useForm().form;

  const [progressArray, setProgressArray] = useState([]);
  const [commentArray, setCommentArray] = useState([]);
  const [practiceArray, setPracticeArray] = useState([]);

  const getDataSchool = async () => {
    let data = await getSchool();
    let arr = [];
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.school_id,
          school_name: item.school_name,
          school_address: item.school_address
        });
      });
      setSchoolList(arr);
    }
  };

  const getData = async (search) => {
    let data = await getSuperviseComment(search);
    let arr = [];
    console.log("data:", data.data);
    if (data.data !== undefined) {
      data.data.map((item) => {
        arr.push({
          id: item.sup_id,
          fullName: item.user.first_name + " " + item.user.last_name,
          school_name: item.school.school_name,
          date: item.sup_date,
          user: item.user,
          progress: item.progress,
          comment: item.comment
        });
      });
      setDataList(arr);
    }
  };

  const getDataSupForm = async () => {
    let data = await getSupForm();
    setDataSupForm(data.data);
  };
  const getDataSupIssues = async () => {
    let data = await getSupIssues();
    setDataSupIssues(data.data);
  };
  const getDataSupMethod = async () => {
    let data = await getSupMethod();
    setDataSupMethod(data.data);
  };
  const getDataSupTool = async () => {
    let data = await getSupTool();
    setDataSupTool(data.data);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setDataId("");
    setFileArr([]);
    setFileList([]);
    setDataArray("");
  };

  const addSuccess = () => {
    Modal.success({
      content: "บันทึกข้อมูลเรียบร้อย"
    });
  };

  const edit = async (id) => {
    setDataId(id);
    showModal();
    let supForm = [];
    let supIssue = [];
    let supMethod = [];
    let supTool = [];
    let file_arr = [];
    let file_objArr = [];
    let supName = [];
    let sixfocus = [];
    let data = await getSuperviseById(id);

    let sup_form_other = "";

    console.log("edit", data.data);

    setDataArray(data.data);

    if (data.data.form !== undefined && data.data.form.length > 0) {
      for (let i = 0; i < data.data.form.length; i++) {
        supForm.push(data.data.form[i].sup_form_value);
        if (data.data.form[i].sup_form_other !== "") {
          sup_form_other = data.data.form[i].sup_form_other;
        }
      }
    }

    if (data.data.issue !== undefined && data.data.issue.length > 0) {
      for (let i = 0; i < data.data.issue.length; i++) {
        supIssue.push(data.data.issue[i].sup_iussues_value);
      }
    }

    if (data.data.method !== undefined && data.data.method.length > 0) {
      for (let i = 0; i < data.data.method.length; i++) {
        supMethod.push(data.data.method[i].sup_method_value);
      }
    }

    if (data.data.tool !== undefined && data.data.tool.length > 0) {
      for (let i = 0; i < data.data.tool.length; i++) {
        supTool.push(data.data.tool[i].sup_tool_value);
      }
    }

    if (data.data.sixFocus !== undefined && data.data.sixFocus.length > 0) {
      for (let i = 0; i < data.data.sixFocus.length; i++) {
        sixfocus.push(data.data.sixFocus[i].sup_6focus_value);
      }
    }

    if (data.data.files !== undefined && data.data.files.length > 0) {
      for (let i = 0; i < data.data.files.length; i++) {
        file_arr.push(data.data.files[i].uid);
        file_objArr.push({
          uid: data.data.files[i].uid,
          name: data.data.files[i].file_old_name, // file name
          status: "done",
          response: { status: "success" },
          url: data.data.files[i].url
        });
      }
    }

    if (data.data.name !== undefined && data.data.name.length > 0) {
      for (let i = 0; i < data.data.name.length; i++) {
        supName.push(data.data.name[i].sup_name);
      }
    }

    //alert(JSON.stringify(data.data.name));
    setFileArr(file_arr);
    setFileList(file_objArr);

    setProgressArray(data.data.progress);
    setCommentArray(data.data.comment);
    setPracticeArray(data.data.practice);

    form.setFieldsValue({
      school_id: data.data.school_id,
      year: data.data.year,
      term: data.data.term,
      sup_date: moment(data.data.sup_date),
      sup_practice: data.data.sup_practice,
      form: supForm,
      issues: supIssue,
      method: supMethod,
      tool: supTool,
      sup_results: data.data.sup_results,
      sup_comment: data.data.sup_comment,
      sup_form_other: sup_form_other,
      supervisor: supName,
      six: sixfocus
    });
  };

  const del = async (id) => {
    //alert("del:" + id);
    await deleteSupervise(id);
    message.success("ลบข้อมูลเรียบร้อย");
    getData();
  };

  const handleSubmit = async (data) => {
    let result;
    //alert(JSON.stringify(data));

    if (dataId == "") {
      //result = await createSupervise(data, fileArr);
    } else {
      result = await createComment(dataId, data);
    }

    if (result.status === true) {
      //message.success("บันทึกข้อมูลเรียบร้อย");
      addSuccess();
    } else {
      message.error(result.error.data.message);
    }

    handleCancel();
    getData();
    setDataId("");
  };

  const handleSubmitSearch = async (data) => {
    getData(data);
  };

  const columns = [
    {
      title: "วันที่",
      dataIndex: "date",
      key: "date",
      width: "10%",
      className: "td_top",
      render: (text, record) => {
        return dayjs(record.sup_date).format("DD/MM/YYYY");
      }
    },
    {
      title: "โรงเรียน",
      dataIndex: "school_name",
      key: "school_name",
      width: "30%",
      className: "td_top"
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "user.id",
      key: "user.id",
      width: "20%",
      className: "td_top",
      render: (text, record) =>
        record.user.first_name + " " + record.user.last_name
    },
    {
      title: "ความคิดเห็น",
      dataIndex: "sup_id",
      key: "sup_id",
      width: "20%",
      className: "td_top",
      render: (text, record) => {
        if (record.comment !== undefined) {
          return <Comment data={record.comment} getData={getData} />;
        }
      }
    },
    {
      title: "บันทึกความคิดเห็นผู้บริหารการศึกษา",
      key: "action",
      className: "td_top",
      render: (text, record) => <Control rowId={record.id} progress={edit} />
    }
  ];

  const uploadFile = async (option) => {
    const { onSuccess, onError, file, action, onProgress } = option;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("uid", file.uid);
      formData.append("path", "supervise");

      let url = BASE_URL + "/upload";
      let upload = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      setFileArr([...fileArr, file.uid]);
      setFileList([...fileList, file]);
      onSuccess("Ok");
    } catch (err) {
      console.log("err:" + err);
      //return { status: false };
    }
  };

  const deleteFile = (f) => {
    let uid = f.uid;
    let files = fileArr;
    let index = files.indexOf(uid);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setFileArr([files]);
    let file_arr = [];
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].uid !== uid) {
        file_arr.push(fileList[i]);
      }
    }
    setFileList(file_arr);
  };

  useEffect(() => {
    const startFetchData = async () => {
      await getData();
      await getDataSchool();
      await getDataSupForm();
      await getDataSupIssues();
      await getDataSupMethod();
      await getDataSupTool();
    };
    startFetchData();
  }, []);

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>บันทึกความคิดเห็น</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
        <PageHeader
          ghost={false}
          title="ข้อมูลนิเทศ"
          subTitle=""
          extra={[]}
        ></PageHeader>
        {showSearch === true && (
          <UserSearch
            form={formSearch}
            schools={schoolList}
            handleSubmit={handleSubmitSearch}
          />
        )}
        <Table rowKey="id" dataSource={dataList} columns={columns} />
      </div>
      <Modal
        title={`บันทึกความคิดเห็น`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={"1"}
            type="primary"
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            บันทึก
          </Button>,
          <Button key={"2"} onClick={handleCancel}>
            ยกเลิก
          </Button>
        ]}
      >
        <SuperviseForm
          handleSubmit={handleSubmit}
          form={form}
          schools={schoolList}
          dataSupForm={dataSupForm}
          dataSupIssues={dataSupIssues}
          dataSupMethod={dataSupMethod}
          dataSupTool={dataSupTool}
          id={dataId}
          uploadFile={uploadFile}
          deleteFile={deleteFile}
          fileList={fileList}
          dataArray={dataArray}
          view={true}
          comment={true}
          progressArray={progressArray}
          commentArray={commentArray}
          practiceArray={practiceArray}
        />
      </Modal>
    </Content>
  );
};

export default SupervisionComment;
