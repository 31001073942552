import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  DatePicker,
  InputNumber,
  TreeSelect,
  Tabs,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Progress from "./../common/progress";
import Practice from "./../common/practice";
import Comment from "./../common/comment";
import { userType } from "./../../config/app";

const { Option } = Select;
const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;
const { TabPane } = Tabs;

const year = () => {
  let start = 2021;
  let end = parseInt(dayjs().format("YYYY")) + 1;
  let yearArr = [];
  for (let i = start; i <= end; i++) {
    yearArr.push(i + 543);
  }
  return yearArr.sort(function (a, b) {
    return b - a;
  });
};

const treeData = [
  {
    title: "ข้อ 1 ยกระดับผลสัมฤทธ",
    value: "0-0",
    key: "0-0",
    checkable: false,
    children: [
      {
        title: "O-NET",
        value: "0-0-0",
        key: "0-0-0",
      },
      {
        title: "NT",
        value: "0-0-1",
        key: "0-0-1",
      },
      {
        title: "RT",
        value: "0-0-2",
        key: "0-0-2",
      },
      {
        title: "การจัดกิจกรรม Active Learning",
        value: "0-0-3",
        key: "0-0-3",
      },
      {
        title: "คุณลักษณะอันพึงประสงค์",
        value: "0-0-4",
        key: "0-0-4",
      },
      {
        title: "เด็กพิเศษเรียนรวม",
        value: "0-0-5",
        key: "0-0-5",
      },
    ],
  },
  {
    title: "ข้อ 2 ทักษะคิดแคล่วคล่อง",
    value: "0-1",
    key: "0-1",
    checkable: false,
    children: [
      {
        title: "การสอนวิทยาการคำนวณ",
        value: "0-1-0",
        key: "0-1-0",
      },
      {
        title: "การสอนตามแนวทางสะเต็มศึกษา",
        value: "0-1-1",
        key: "0-1-1",
      },
      {
        title: "การสอนแบบโครงงาน",
        value: "0-1-2",
        key: "0-1-2",
      },
    ],
  },
  {
    title: "ข้อ 3 ว่องไวเขียนอ่าน",
    value: "0-2",
    key: "0-2",
    checkable: false,
    children: [
      {
        title: "การอ่านออกเขียนได้ ชั้น ป.1",
        value: "0-2-0",
        key: "0-2-0",
      },
      {
        title: "การอ่านคล่องเขียนคล่อง ชั้น ป.2",
        value: "0-2-1",
        key: "0-2-1",
      },
      {
        title: "การอ่านรู้เรื่อง เขียนสื่อความได้ ชั้น ป.3 - 6",
        value: "0-2-2",
        key: "0-2-2",
      },
      {
        title: "การอ่านตามแนวทางการประเมิน PISA ชั้น ม.1 - 3",
        value: "0-2-3",
        key: "0-2-3",
      },
      {
        title: "ทักษะภาษาอังกฤษเพื่อการสื่อสาร",
        value: "0-2-4",
        key: "0-2-4",
      },
    ],
  },
  {
    title: "ข้อ 4 สื่อสารเทคโนโลยี",
    value: "0-3",
    key: "0-3",
    checkable: false,
    children: [
      {
        title: "การใช้เทคโนโลยีเพื่อบริหารจัดการศึกษา",
        value: "0-3-0",
        key: "0-3-0",
      },
      {
        title: "การประยุกต์ใช้สื่อและเทคโนโลยีเพื่อการเรียนการสอนของครู",
        value: "0-3-1",
        key: "0-3-1",
      },
      {
        title: "การใช้เทคโนโลยีเพื่อการเรียนรู้ของนักเรียน",
        value: "0-3-2",
        key: "0-3-2",
      },
    ],
  },
  {
    title: "ข้อ 5 จัดการดีสิ่งแวดล้อม",
    value: "0-4",
    key: "0-4",
    checkable: false,
    children: [
      {
        title: "ความปลอดภัยในสถานศึกษา",
        value: "0-4-0",
        key: "0-4-0",
      },
      {
        title: "สภาพแวดล้อมที่เอื้อต่อการเรียนร",
        value: "0-4-1",
        key: "0-4-1",
      },
      {
        title: "ห้องเรียนคุณภาพ",
        value: "0-4-2",
        key: "0-4-2",
      },
      {
        title:
          "การจัดกิจกรรมการเรียนรู้เสริมสร้างจิตสำนึกการอนุรักษ์ธรรมชาติและสิ่งแวดล้อม",
        value: "0-4-3",
        key: "0-4-3",
      },
    ],
  },
  {
    title: "ข้อ 6 น้อมนำศาสตร์พระราชา",
    value: "0-5",
    key: "0-5",
    checkable: false,
    children: [
      {
        title: "กิจกรรมน้อมนำศาสตร์พระราชาสู่การปฏิบัต",
        value: "0-5-0",
        key: "0-5-0",
      },
      {
        title: "กิจกรรมส่งเสริมทักษะด้านอาชีพ",
        value: "0-5-1",
        key: "0-5-1",
      },
      {
        title: "กิจกรรมส่งเสริมทักษะชีวิต",
        value: "0-5-2",
        key: "0-5-2",
      },
    ],
  },
];

const SuperviseForm = ({
  handleSubmit,
  schools,
  dataSupForm,
  dataSupIssues,
  dataSupMethod,
  dataSupTool,
  id,
  form,
  uploadFile,
  deleteFile,
  fileList,
  dataArray,
  view,
  progress,
  comment,
  practice,
  progressArray,
  commentArray,
  practiceArray,
  useView,
}) => {
  const [supFormOther, setSupFormOther] = useState(false);
  const [supIssuesOther, setSupIssuesOther] = useState(false);
  const [supMethodOther, setSupMethodOther] = useState(false);
  const [supToolOther, setSupToolOther] = useState(false);
  const [sixFocusPoints, setSixFocusPoints] = useState(false);
  const [commentData, setCommentData] = useState(false);
  const supFormHandleChange = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupForm.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item) {
          if (data.other === 1) {
            chk = true;
          }
        }
      });
    });

    setSupFormOther(chk);
  };

  const supIssuesHandleChange = (value) => {
    let result;
    let chk = false;
    let sub = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupIssues.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item) {
          if (data.other === 1) {
            chk = true;
          }
        }

        if (item === "SI01") {
          sub = true;
        }
      });
    });
    setSupIssuesOther(chk);
    setSixFocusPoints(sub);
  };

  const supMethodHandleChange = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupMethod.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item) {
          if (data.other === 1) {
            chk = true;
          }
        }
      });
    });
    setSupMethodOther(chk);
  };

  const supToolHandleChange = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupTool.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item) {
          if (data.other === 1) {
            chk = true;
          }
        }
      });
    });
    setSupToolOther(chk);
  };

  const supFormEditData = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupForm.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item.sup_form_value) {
          if (data.other === 1) {
            chk = true;
          }
        }

        if (item.sup_form_value === "SF05") {
          chk = true;
        }
      });
    });
    setSupFormOther(chk);
  };

  const supIssuesEditData = (value) => {
    let result;
    let chk = false;
    let sub = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupIssues.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item.sup_iussues_value) {
          if (data.other === 1) {
            chk = true;
          }
        }
        if (data.value === "SI01") {
          sub = true;
        }

        if (item.sup_iussues_value === "SI8") {
          chk = true;
        }
      });
    });
    setSupIssuesOther(chk);
    setSixFocusPoints(sub);
  };

  const supMethodEditData = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupMethod.filter((data) => {
        //alert(data.value + "===" + value);
        if (data.value === item.sup_method_value) {
          if (data.other === 1) {
            chk = true;
          }
        }

        if (item.sup_method_value === "SM14") {
          chk = true;
        }
      });
    });
    setSupMethodOther(chk);
  };

  const supToolEditData = (value) => {
    let result;
    let chk = false;
    //let ex = value.split(",");
    value.map((item) => {
      dataSupTool.filter((data) => {
        if (data.value === item) {
          if (data.other === 1) {
            chk = true;
          }
        }

        if (item.sup_tool_value === "ST12") {
          chk = true;
        }
      });
    });
    setSupToolOther(chk);
  };

  useEffect(() => {
    if (dataArray.form !== undefined) {
      supFormEditData(dataArray.form);
      //alert(JSON.stringify(dataArray.form));
    } else {
      setSupFormOther(false);
    }
    //
    if (dataArray.issue !== undefined) {
      supIssuesEditData(dataArray.issue);
      //alert(JSON.stringify(dataArray.form));
    } else {
      setSupIssuesOther(false);
    }
    //
    if (dataArray.method !== undefined) {
      supMethodEditData(dataArray.method);
      //alert(JSON.stringify(dataArray.form));
    } else {
      setSupMethodOther(false);
    }
    //
    if (dataArray.tool !== undefined) {
      supToolEditData(dataArray.tool);
    } else {
      setSupToolOther(false);
    }
  }, [dataArray]);

  const tProps = {
    treeData,
    value: [],
    onChange: null,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "เลือกจุดเน้น 6 ข้อ",
    style: {
      width: "100%",
    },
  };

  const chkBoxComment = () => {
    let r = false;
    let userType = localStorage.getItem("user_type");
    if (userType == "2") {
      r = true;
    }
    return r;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      id="myForm"
      onFinish={handleSubmit}
      className={"supForm"}
    >
      <Form.Item
        label="โรงเรียน"
        name="school_id"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select showSearch placeholder="เลือกสังกัด" disabled={view}>
          {schools.map((item, index) => (
            <Option key={`data_school_${index}`} value={item.id}>
              {item.school_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="ปีการศึกษา"
        name="year"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
        disabled={view}
      >
        <Select placeholder="ปีการศึกษา" disabled={view}>
          {year().map((item, index) => (
            <Option key={`year_${index}`} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="ภาคเรียน"
        name="term"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <InputNumber placeholder="" style={{ width: "100%" }} disabled={view} />
      </Form.Item>
      <Form.Item
        label="วันที่"
        name="sup_date"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <DatePicker
          format={`DD/MM/YYYY`}
          style={{ width: "100%" }}
          disabled={view}
        />
      </Form.Item>
      <Form.Item
        label="ผู้รับการนิเทศ"
        name="supervisor"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select
          showSearch
          mode="tags"
          placeholder="ผู้รับการนิเทศ"
          disabled={view}
        ></Select>
      </Form.Item>
      <Form.Item
        label="รูปแบบการนิเทศ"
        name="form"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="รูปแบบการนิเทศ"
          onChange={supFormHandleChange}
          disabled={view}
        >
          {dataSupForm.map((item, index) => (
            <Option key={`data_SupForm_${index}`} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {supFormOther === true && (
        <Form.Item label="รายละเอียดรูปแบบการนิเทศอื่นๆ" name="sup_form_other">
          <Input style={{ width: "100%" }} disabled={view} />
        </Form.Item>
      )}
      <Form.Item
        label="ประเด็นการนิเทศ"
        name="issues"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="ประเด็นการนิเทศ"
          onChange={supIssuesHandleChange}
          disabled={view}
        >
          {dataSupIssues.map((item, index) => (
            <Option key={`data_SupIssues_${index}`} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {sixFocusPoints === true && (
        <Form.Item
          label="จุดเน้น 6 ข้อ"
          name="six"
          placeholder="เลือกจุดเน้น 6 ข้อ"
        >
          <TreeSelect
            placeholder="เลือกจุดเน้น 6 ข้อ"
            disabled={view}
            {...tProps}
          />
        </Form.Item>
      )}

      {supIssuesOther === true && (
        <Form.Item
          label="รายละเอียดประเด็นการนิเทศอื่นๆ"
          name="sup_issues_other"
        >
          <Input style={{ width: "100%" }} disabled={view} />
        </Form.Item>
      )}
      <Form.Item
        label="วิธีการนิเทศ"
        name="method"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="วิธีการนิเทศ"
          onChange={supMethodHandleChange}
          disabled={view}
        >
          {dataSupMethod.map((item, index) => (
            <Option key={`data_SupMethod_${index}`} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {supMethodOther === true && (
        <Form.Item label="รายละเอียดวิธีการนิเทศอื่นๆ" name="sup_method_other">
          <Input style={{ width: "100%" }} disabled={view} />
        </Form.Item>
      )}
      <Form.Item
        label="เครื่องมือนิเทศ"
        name="tool"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="เครื่องมือนิเทศ"
          onChange={supToolHandleChange}
          disabled={view}
        >
          {dataSupTool.map((item, index) => (
            <Option key={`data_SupTool_${index}`} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {supToolOther === true && (
        <Form.Item label="รายละเอียดเครื่องมือนิเทศอื่นๆ" name="sup_tool_other">
          <Input style={{ width: "100%" }} disabled={view} />
        </Form.Item>
      )}
      <Form.Item
        label="ผลการนิเทศ"
        name="sup_results"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <TextArea rows={4} disabled={view} />
      </Form.Item>
      <Form.Item
        label="ข้อเสนอแนะ/ข้อสังเกต/ข้อค้นพบ"
        name="sup_comment"
        rules={[{ required: !view, message: "กรุณาใส่ข้อมูล" }]}
      >
        <TextArea rows={4} disabled={view} />
      </Form.Item>
      <Upload
        customRequest={(option) => uploadFile(option)}
        onRemove={deleteFile}
        fileList={fileList}
        disabled={view}
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>แนบไฟล์เอกสาร</Button>
      </Upload>
      {practice !== undefined && practice === true && (
        <div style={{ marginTop: 30 }}>
          <Form.Item
            label="การปฎิบัติของผู้รับนิเทศ"
            name="practice"
            rules={[]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </div>
      )}
      {progress !== undefined && progress === true && (
        <div style={{ marginTop: 30, display: "none" }}>
          <Form.Item label="ความก้าวหน้า" name="progress">
            <TextArea rows={4} />
          </Form.Item>
        </div>
      )}
      {comment !== undefined && comment === true && chkBoxComment() === true && (
        <div style={{ marginTop: 30 }}>
          <Form.Item
            label="แสดงความคิดเห็น"
            name="comment"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูล" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </div>
      )}
      {/*---------*/}
      <div style={{ marginTop: 30 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="ความก้าวหน้า" key="1">
            <Progress data={progressArray !== undefined ? progressArray : []} />
          </TabPane>
          <TabPane tab="การปฎิบัติของผู้รับการนิเทศ" key="3">
            <Practice data={practiceArray !== undefined ? practiceArray : []} />
          </TabPane>
          <TabPane tab="ความคิดเห็น" key="2">
            <Comment data={commentArray !== undefined ? commentArray : []} />
            {view === true && (
              <div>
                {chkBoxComment() === true && (
                  <div style={{ display: "none" }}>
                    แสดงความคิดเห็น
                    <TextArea
                      rows="4"
                      style={{ width: "100%" }}
                      onChange={(e) => setCommentData(e.target.value)}
                      defaultValue={commentData}
                    />
                    <Button type="primary" style={{ marginTop: 10 }}>
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
      {/*---------*/}
    </Form>
  );
};

export default SuperviseForm;
