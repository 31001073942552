import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Card } from "antd";
import { userType } from "./../../config/app";

const { Option } = Select;

const UserSearch = ({ handleSubmit, schools, form }) => {
  const _span = 8;
  return (
    <Card style={{ marginBottom: 16 }} type="inner" title="ค้นหา">
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        id="myFormSearch"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={_span}>
            <Form.Item label="ชื่อ" name="first_name">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={_span}>
            <Form.Item label="นามสกุล" name="last_name">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={_span}>
            <Form.Item label="สังกัด" name="school_id">
              <Select showSearch placeholder="เลือกสังกัด">
                {schools.map((item, index) => (
                  <Option key={`search_school_${index}`} value={item.id}>
                    {item.school_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={_span}>
            <Form.Item label="ชื่อผู้ใช้งาน" name="username">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={_span}>
            <Form.Item
              label="อีเมล์"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "รูปแบบอีเมล์ไม่ถูกต้อง",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={_span}>
            <Form.Item label="ประเภทผู้ใช้งาน" name="user_type">
              <Select placeholder="เลือกประเภทผู้ใช้งาน">
                {userType.map((item, index) => (
                  <Option key={`search_user_type_${index}`} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button htmlType={"submit"} type="primary">
            ค้นหา
          </Button>
          &nbsp;
          <Button htmlType={"reset"}>Reset</Button>
        </div>
      </Form>
    </Card>
  );
};

export default UserSearch;
